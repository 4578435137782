/**
 * pv 上报模块
 * @constructor
 * @param {type}    [type] <1|2|3>  1 表示PC端，2表示移动端，3表示小程序
 * @param {number}  [productId]     产品id
 * @param {boolean} [isAdmin]       是否管理员，默认为 false
 * @param {string}  [url]           地址
 */

import uuid from 'uuid';
import { getProductId } from 'components/util';
import './aegis';
import { init as horizonInit } from './horizon';
const unionId = uuid();
class PvCollector {
  constructor({ type = 2, productId = '0', isAdmin = false, url = location.href }) {
    this.report = this.report.bind(this);
    this.horizonTrack = this.horizonTrack.bind(this);
    // 1 表示PC端，2表示移动端，3表示小程序
    this.type = type;
    this.isAdmin = isAdmin;
    this.url = url;
    this.productId = getProductId();
    this.referer = encodeURIComponent(document.referrer);
    this.params = [
      `type=${type}`,
      `fid=${productId}`,
      `is_admin=${Number(isAdmin)}`,
      `location=${encodeURIComponent(url)}`,
      `union_id=${unionId}`,
      `referer=${encodeURIComponent(document.referrer)}`,
    ];
  }

  report() {
    this.horizonTrack();
  }

  /**
      * pv collector 只上报
      * horizon是加载即触发pv上报，已加载可能是单页部分，需要手动触发track
      */
  horizonTrack() {
    const horizon  = window._horizon;
    if (horizon && horizon.track) {
      horizon.setObject(this.productId);
      horizon.setExtraProp('request_type', this.type);
      horizon.setExtraProp('referer', this.referer);
      horizon.setExtraProp('is_admin', this.isAdmin);
      horizon.setExtraProp('document_path', encodeURIComponent(this.url));
      horizon.track();
      const uid = horizon.getCookie(horizon.cookie_key_uid);
      const wjUserIdKey = '__wj_userid';
      if (!horizon.getCookie(wjUserIdKey)) {
        // 设置 uid
        const hour = 60 * 60;
        const day = hour * 24;
        const year = day * 365;
        const decade = year * 10;
        horizon.setCookie(wjUserIdKey, uid, decade);
      }
    } else {
      horizonInit(this.horizonTrack);
    }
  }
}

export { PvCollector };

