/* eslint-disable camelcase */
import {
  convertNumber, isLessThanOneWeek, RenderLinkContext, renderTag,
} from './utils/utils';
import { Button, Divider, Input, Skeleton, Tooltip } from 'tdesign-react';
import {
  CommentIcon, LikedIcon, LikeIcon,
  PositiveIcon, ShareIcon, Voting,
} from './utils/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.less';
import { dangerouslySetInnerHTML, replaceAnchorHref } from 'components/render-rich-text';
import { href_post, href_profile, hrefTopicDetail } from 'components/href-helper';
import formatTime from 'components/util/formatTime';
import { useThxRoadmapUser } from 'components/hooks/change-log/useThxRoadmapUser';
import _ from 'lodash-es';
import { Avatar as AvatarCustom } from 'components/user';
import { clipboardText } from '@tencent/tc-clipboard';
import { post_api_posts_replies } from '../api';
import { message } from '@tencent/ten-design-react';
import { ArrowDownLine, ArrowUpLine, DateLine } from '@tencent/coral-icon';
import { getChangeLogTitleByTimestamp } from 'components/util/change-log';
import PreviewImage from 'components/preview-image/preview-image-pc';
import { LoadMore } from '../load-more';

const cls = 'change-log-pc';

export const Heading = ({ heading, isNew, isListView = true }) => (
  <div className={`${cls}-detail-heading`}>
    <span className={`${cls}-detail-heading__icon`}><DateLine size="24px" /></span>
    <span className={`${cls}-detail-heading__title`}>{isListView ? '更新日志 ' : ''}{heading.date}</span>
    {!!heading.version && <span className={`${cls}-detail-heading__version`}>ver {heading.version}</span>}
    {isNew && <span className={`${cls}__tag ${cls}__tag--official ${cls}__tag--right`}>新</span>}
  </div>
);

const Author = ({ author }) => (
  <div className={`${cls}-detail-author`}>
    <a href={href_profile(author?.id)} target="_blank" rel="noreferrer">
      <AvatarCustom
        src={author?.avatar}
        className={`${cls}-detail-author__avatar`}
      />
    </a>
    <a href={href_profile(author?.id)} target="_blank" rel="noreferrer">
      <span className={`${cls}-detail-author__name`}>
        {author?.name}
      </span>
    </a>
    <span className={`${cls}-detail-author__date`}>
      发布于 {formatTime(author?.date, 'MM月dd日 hh:mm')}
    </span>
  </div>
);

const BlogItem = ({ item, changeLogId = '', dataIndex }) => (
  <div className={`${cls}-detail-item ${changeLogId}`} data-index={dataIndex}>
    <div className={`${cls}-detail-item__heading`}>{item.title}</div>
    <PreviewImage content={replaceAnchorHref(item.detail)}>
      <div className={`${cls}-detail-item__content`} dangerouslySetInnerHTML={{ __html: replaceAnchorHref(item.detail) }} />
    </PreviewImage>
  </div>
);

const Appreciation = ({ students }) => (
  <div className={`${cls}-detail-appreciation`}>
    <div className={`${cls}-detail-appreciation__heading`}>本次更新，感谢以下同学建议</div>
    <Divider className={`${cls}-detail-appreciation__divider`}></Divider>
    {
      <div className={`${cls}-detail-appreciation__students-1`}>
        {
          _.uniqBy(students, 'userId').map((student, index) => <a key={index} href={href_profile(student.userId)}
            rel="noreferrer" target="_blank" className={`${cls}-detail-appreciation__student-container`}>
            <AvatarCustom
              src={student.avatarUrl} className="student-avatar"
            />
            <span className="student-nickname">{student.nickName}</span>
          </a>)
        }
      </div>
    }
  </div>
);

const AppreciationWithVote = ({ link, productId }) => {
  const { isLoading, error, data } = useThxRoadmapUser({
    productId: String(productId),
    topicLink: link,
  });

  const renderContent = () => {
    if (error) {
      return <div></div>;
    }
    return (
      <div className={`${cls}-detail-appreciation`}>
        <Skeleton animation="gradient" theme="paragraph" loading={isLoading} >
          {!!data
          && <>
            <div className={`${cls}-detail-appreciation__wrapper`}>
              <div className={`${cls}-detail-appreciation__heading`}>本次更新，感谢在以下话题中的讨论和建议的每一位</div>
              <div className={`${cls}-detail-appreciation__students-1`}>
                {
                  _.unionBy(data.post_users || [], 'user_id')
                    .slice(0, 7)
                    .map((student, index) => (
                      <a key={index} href={href_profile(student.user_id)}
                        rel="noreferrer" target="_blank" className={`${cls}-detail-appreciation__student-link`}>
                        <Tooltip content={student.nick_name} showArrow={false} theme="light">
                          <img src={student.avatar_url} className="student-avatar" alt={student.nick_name} />
                        </Tooltip>
                      </a>
                    ))
                }
              </div>
            </div>
            <Divider className={`${cls}-detail-appreciation__divider`}></Divider>
            <div className={`${cls}-detail-appreciation__vote`} onClick={() => hrefTopicDetail(productId, data.id)} role="presentation">
              <span className={`${cls}-detail-appreciation__vote--hint`}><Voting /></span>
              <span className={`${cls}-detail-appreciation__vote--main`}>{data.title || ''}</span>
              <span className={`${cls}-detail-appreciation__vote--hint`}>{convertNumber(data.stat?.content_count || 0)} 讨论</span>
              <span className={`${cls}-detail-appreciation__vote--hint`}>{convertNumber(data.stat?.read_count || 0)} 次阅读</span>
            </div>
          </>
          }
        </Skeleton>
      </div>
    );
  };

  return renderContent();
};

const Control = ({ likePost, postId, onClickComment, onClickLike, isShare }) => (
  <div className={`${cls}-detail-control`}>
    {
      isShare ? (
        <>
          <a className={`${cls}-detail-control__icon ${cls}-detail-control__icon--margin-right ${cls}-detail-control__icon--float-right`}
            style={likePost.like_flag ? { color: '#2c71ff' } : {}}
            href={href_post(postId)}
            rel="noreferrer"
            target="_blank"
          >
            {likePost.like_flag ? <LikedIcon /> : <LikeIcon viewBox="0 -1 24 24" />}
            <span className={`${cls}-detail-control__text`}>{convertNumber(likePost.like_count) || '赞'}</span>
          </a>
          <a className={`${cls}-detail-control__icon ${cls}-detail-control__icon--margin-right`}
            href={href_post(postId)}
            rel="noreferrer"
            target="_blank"
          >
            <CommentIcon />
          </a>
          <a className={`${cls}-detail-control__icon`}
            href={href_post(postId)}
            rel="noreferrer"
            target="_blank"
          >
            <ShareIcon />
          </a>
        </>
      ) : (
        <>
          <div className={`${cls}-detail-control__icon ${cls}-detail-control__icon--margin-right ${cls}-detail-control__icon--float-right`}
            style={likePost.like_flag ? { color: '#2c71ff' } : {}}
            onClick={onClickLike}
            role="presentation"
          >
            {likePost.like_flag ? <LikedIcon /> : <LikeIcon viewBox="0 -1 24 24" />}
            <span className={`${cls}-detail-control__text`}>{convertNumber(likePost.like_count) || '赞'}</span>
          </div>
          <div className={`${cls}-detail-control__icon ${cls}-detail-control__icon--margin-right`}
            onClick={onClickComment}
            role="presentation"
          >
            <CommentIcon />
          </div>
          <div className={`${cls}-detail-control__icon`} onClick={() => clipboardText(new URL(href_post(postId), window.location.href).toString(), '复制链接成功，去粘贴分享吧。')} role="presentation">
            <ShareIcon />
          </div>
        </>
      )
    }
  </div>
);

const Replies = ({
  replies, authorId, replyCount,
  postId, userData, productId,
  showCommentArea, setShowCommentArea,
}) => {
  const [replyValues, setReplyValues] = useState(Object.values(replies || {}));
  const [commentText, setCommentText] = useState('');
  const [replyCountCopy, setReplyCountCopy] = useState(replyCount);
  const [isCommenting, setIsCommenting] = useState(false);
  const onSubmitComment = () => {
    if (!commentText) return;
    setIsCommenting(true);

    post_api_posts_replies({
      productId,
      postId,
      text: commentText,
    }).then((res) => {
      message.success('评论成功');
      setCommentText('');
      setShowCommentArea(false);
      replyValues.unshift(res.data.replies[res.data.latest_reply_id].self);
      if (replyValues.length > 3) replyValues.pop();
      setReplyValues([...replyValues]);
      setReplyCountCopy(replyCountCopy + 1);
      setIsCommenting(false);
    })
      .catch(err => message.error(err.message));
  };

  return (
    <div
      className={`${cls}-detail-comment`}
      style={{
        display: !replyCountCopy && !showCommentArea ? 'none' : 'block',
        paddingBottom: replyCountCopy > 3 ? '0' : '12px',
      }}
    >
      {showCommentArea
        && <AddComment
          userData={userData}
          onSubmitComment={onSubmitComment}
          commentText={commentText}
          setCommentText={setCommentText}
          isCommenting={isCommenting}
        />
      }
      <CommentList
        href={href_post(postId)}
        reply_count={replyCountCopy}
        comments={replyValues}
        userData={userData}
        authorId={authorId}
        replies={replies}
      />
    </div>
  );
};

export const CommentArea = ({ postInfo, userData, productId, setIsShowLogin, likePost, onClickLike }) => {
  const [showCommentArea, setShowCommentArea] = useState(false);

  const onClickComment = () => {
    if (!userData || !setIsShowLogin) return;
    if (!userData.user_id) {
      setIsShowLogin(true);
      return;
    }
    setShowCommentArea(!showCommentArea);
  };

  return (
    <>
      <Control
        postId={postInfo.id}
        likePost={likePost}
        onClickComment={onClickComment}
        onClickLike={onClickLike}
        // 这里用userData来判断是不是弹窗页面，因为弹窗页面拿不到userData
        isShare={!userData}
      />
      <Replies
        replies={postInfo.replies}
        authorId={postInfo.user_id}
        replyCount={postInfo.reply_count}
        postId={postInfo.id}
        userData={userData}
        isLocked={postInfo.is_locked}
        showCommentArea={showCommentArea}
        setShowCommentArea={setShowCommentArea}
        productId={productId}
      />
    </>
  );
};
export const ChangeLog = ({ changeLog, style = {}, productId }) => {
  const changeLogRef = useRef(null);
  const [showMask, setShowMask] = useState(false);

  useEffect(() => {
    if (!changeLog.showMask || !changeLogRef.current) return;
    setShowMask(changeLogRef.current.offsetHeight >= 500);
  }, [changeLog.showMask]);

  return (
    <div className={`${cls}-detail`} style={style} ref={changeLogRef}>
      <Heading heading={{
        date: getChangeLogTitleByTimestamp(changeLog.versionDate),
        version: changeLog.version,
      }} isNew={isLessThanOneWeek(changeLog.versionDate)} isListView={!changeLog.author}
      />
      {/* 这里通过有没有author来判断是不是在列表页面，只有products__change-log和products__change-log-share传递了author，主页以及详情页 */}
      {/* 有自己的作者组件，不需要展示Author组件 */}
      {!!changeLog.author && <Author author={changeLog.author} />}
      {
        changeLog.content.map((item, index) => (
          <BlogItem item={item} key={index} changeLogId={changeLog.id || ''} dataIndex={index} />
        ))
      }

      {
        changeLog.thankPostIdList.length > 0 && <Appreciation students={changeLog.thankPostIdList} />
      }

      {
        changeLog.thankVoteIdList.map((item, index) => (
          <AppreciationWithVote link={item} key={index} productId={productId} />
        ))
      }

      <div className={`${cls}-detail__mask`} style={{ display: showMask ? 'block' : 'none' }}></div>
    </div>
  );
};

const MenuBlog = ({ menus, heading, isNew, handleClick, defaultExpand = false, selectedIndex, handleMenuScroll }) => {
  const [toExpand, setToExpand] = useState(defaultExpand);
  const ref = useRef(null);

  useEffect(() => {
    if (toExpand || selectedIndex < 0) return;
    setToExpand(true);
  }, [selectedIndex, toExpand]);

  useEffect(() => {
    if (!ref.current || !toExpand || selectedIndex < 0) return;
    handleMenuScroll(ref.current.offsetTop, ref.current.offsetHeight);
  }, [handleMenuScroll, toExpand, selectedIndex]);

  return (
    <div className={`${cls}-menu__item`} ref={ref}>
      <span className={`${cls}-menu__heading`} onClick={() => setToExpand(!toExpand)} role="presentation"
        style={{ cursor: selectedIndex > -1 ? 'not-allowed' : 'pointer' }}>
        <span className={`${cls}-menu__heading-title`}>{heading.date}</span>
        {isNew && <span className={`${cls}__tag ${cls}__tag--official ${cls}__tag--right`}>新</span>}
        <span className={`${cls}-menu__heading-control`}>
          {toExpand ? <ArrowUpLine size={12} fill="rgba(0, 0, 0, .4)" /> : <ArrowDownLine size={12} fill="rgba(0, 0, 0, .4)" />}
        </span>
      </span>
      <div className={`${cls}-menu__titles`} style={{ maxHeight: !toExpand ? '0' : `${menus.length * 60}px` }}>
        {
          menus.map((menu, index) => <div
            className={`${cls}-menu__title ${selectedIndex === index && `${cls}-menu__title--selected`}`} key={index}
            onClick={() => handleClick(index, ref)} role="presentation"
          >
            <span className={`${cls}-menu__title-text`}>{menu}</span>
          </div>)
        }
      </div>
    </div>
  );
};

export const Menu = ({ changeLogs, loadMore, pagination, menuSelected, handleMenuScroll, handleMenuClick }) => (
  <div>
    {
      changeLogs.map((changeLog, index) => <MenuBlog
        menus={changeLog.content.content.map(content => content.title)}
        heading={{
          date: formatTime(changeLog.content.versionDate, 'yyyy.MM.dd'),
        }}
        isNew={isLessThanOneWeek(changeLog.content.versionDate)}
        key={index}
        handleClick={handleMenuClick(changeLog.id)}
        defaultExpand={index < 5}
        selectedIndex={(menuSelected && menuSelected[0] === index) ? menuSelected[1] : -1}
        handleMenuScroll={handleMenuScroll}
      />)
    }
    {/* eslint-disable-next-line camelcase */}
    {pagination?.has_more_pages && <LoadMore onShow={loadMore} />}
  </div>
);

const AddComment = ({ userData, onSubmitComment, commentText, setCommentText, isCommenting }) => (
  <div className={`${cls}-detail-comment__add`}>
    <AvatarCustom
      src={userData.user_avatar}
      className={`${cls}-detail-comment__avatar`}
    />
    <div className={`${cls}-detail-comment__input`}>
      <Input
        placeholder="别害羞，快来说两句吧..."
        value={commentText}
        type="text"
        onChange={(value) => {
          setCommentText(value);
        }}
      />
    </div>
    <Button
      className={`${cls}-detail-comment__button`}
      onClick={onSubmitComment}
      loading={isCommenting}
      style={{ cursor: isCommenting ? 'not-allowed' : 'pointer' }}
    >
      评论{isCommenting ? '中...' : ''}
    </Button>
  </div>
);

const CommentList = ({ comments, href, reply_count, userData, authorId, replies }) => {
  const renderLink = useContext(RenderLinkContext);
  return (
    <div className={`${cls}-detail-comment__list`}>
      {
        comments.map((comment, index) => (
          <div
            className={`${cls}-detail-comment__detail`}
            key={index}
          >
            <a
              href={href_profile(comment.user_id)}
              target="_blank" rel="noreferrer"
              className={`${cls}-detail-comment__link`}
            >
              <AvatarCustom
                className={`${cls}-detail-comment__avatar`}
                src={comment.avatar_url}
              />
            </a>
            <div className={`${cls}-detail-comment__author`}>
              <a
                href={href_profile(comment.user_id)}
                target="_blank" rel="noreferrer"
                className={`${cls}-detail-comment__link${comment.is_admin ? ` ${cls}-detail-comment__link--admin` : ''}`}
              >
                {comment.nick_name}
              </a>
              {userData && renderTag(comment, userData, authorId, cls)}
              {comment.parent_reply_id
                && replies[comment.parent_reply_id]
                && replies[comment.parent_reply_id].nick_name
                && replies[comment.parent_reply_id].user_id
                && <>
                  <div className={`${cls}__arrow-right ${cls}-detail-comment__author--right`}></div>
                  <a
                    href={href_profile(replies[comment.parent_reply_id].user_id)}
                    target="_blank" rel="noreferrer"
                    className={`${cls}-detail-comment__link${replies[comment.parent_reply_id].is_admin ? ` ${cls}-detail-comment__link--admin` : ''}`}
                  >
                    {replies[comment.parent_reply_id].nick_name}
                  </a>
                  {userData && renderTag(replies[comment.parent_reply_id], userData, authorId, cls)}
                </>
              }
              <span className={`${cls}-detail-comment__author--right`}>:</span>
            </div>
            <div
              className={`${cls}-detail-comment__text`}
              dangerouslySetInnerHTML={dangerouslySetInnerHTML(comment.f_reply_text || comment.content, '', '', renderLink === 'on' || comment.is_admin)}
            />
          </div>
        ))
      }
      {
        reply_count > 3 && (
          <div className={`${cls}-detail-comment__more`}>
            <a className={`${cls}-detail-comment__link`} href={href} target="_blank" rel="noreferrer">
              查看全部 {reply_count || 0} 条评论
            </a>
          </div>
        )
      }
    </div>
  );
};

Heading.propTypes = {
  heading: PropTypes.object,
  isNew: PropTypes.bool,
  isListView: PropTypes.bool,
};

Author.propTypes = {
  author: PropTypes.object,
  visible: PropTypes.bool,
};

BlogItem.propTypes = {
  item: PropTypes.object,
  changeLogId: PropTypes.string,
  dataIndex: PropTypes.number,
};

Appreciation.propTypes = {
  students: PropTypes.array,
};

AddComment.propTypes = {
  userData: PropTypes.object,
  commentText: PropTypes.string,
  setCommentText: PropTypes.func,
  onSubmitComment: PropTypes.func,
  isCommenting: PropTypes.bool,
};

CommentList.propTypes = {
  comments: PropTypes.array,
  href: PropTypes.string,
  reply_count: PropTypes.number,
  userData: PropTypes.object,
  authorId: PropTypes.number,
  replies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ChangeLog.propTypes = {
  changeLog: PropTypes.object,
  style: PropTypes.object,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Menu.propTypes = {
  changeLogs: PropTypes.array,
  pagination: PropTypes.object,
  loadMore: PropTypes.func,
  menuSelected: PropTypes.array,
  handleMenuScroll: PropTypes.func,
  handleMenuClick: PropTypes.func,
};

MenuBlog.propTypes = {
  menus: PropTypes.array,
  heading: PropTypes.object,
  isNew: PropTypes.bool,
  handleClick: PropTypes.func,
  defaultExpand: PropTypes.bool,
  selectedIndex: PropTypes.number,
  handleMenuScroll: PropTypes.func,
};

AppreciationWithVote.propTypes = {
  link: PropTypes.string,
};

Replies.propTypes = {
  replies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  authorId: PropTypes.number,
  replyCount: PropTypes.number,
  postId: PropTypes.string,
  userData: PropTypes.object,
  isLocked: PropTypes.bool,
  showCommentArea: PropTypes.bool,
  setShowCommentArea: PropTypes.func,
  productId: PropTypes.string,
};

Control.propTypes = {
  postId: PropTypes.string,
  likePost: PropTypes.object,
  onClickComment: PropTypes.func,
  onClickLike: PropTypes.func,
  isShare: PropTypes.bool,
};

CommentArea.propTypes = {
  postInfo: PropTypes.object,
  userData: PropTypes.object,
  productId: PropTypes.string,
  setIsShowLogin: PropTypes.func,
  likePost: PropTypes.object,
  onClickLike: PropTypes.func,
};
